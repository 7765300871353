import {
	login,
	logout,
	getInfo
} from '@/api/login/login'

import {
	getToken,
	setToken,
	removeToken,
  getPhone,
  setPhone,
  removePhone,
  getVipStatus,
  setVipStatus,
  removeVipStatus
} from '@/utils/auth'

const user = {
	state: {
		token: getToken(),
		name: '',
		keepAlives: []
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, name) => {
		  state.name = name
		},
		SET_KEEP_ALIVE: function(state, keepAlives) {
		  state.keepAlives = keepAlives;
		}

	},
	// 获取用户信息
	GetInfo({ commit }) {
	  return new Promise((resolve, reject) => {
	    getInfo().then(res => {
	      const user = res.user
	      commit('SET_NAME', user.userName)
		  resolve(res)
	    }).catch(error => {
	      reject(error)
	    })

	  })
	},

	actions: {
		// 登录
		Login({ commit }, userInfo) {
			const phone = userInfo.phone;
			const code = userInfo.code;
			return new Promise((resolve, reject) => {
				var data = {
					phone:phone,
					code:code
				};
				login(data).then(res => {
					setToken(res.data.token);
          setPhone(res.data.phone);
          setVipStatus(res.data.vipStatus);
					commit('SET_TOKEN', res.data.token)
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 退出系统
		LogOut({ commit, state }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        removeToken();
        removePhone();
        removeVipStatus();
        resolve(true);
      })
		},

	}
}

export default user

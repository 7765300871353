import {
  request
} from '@/utils/httpRequest';

/* 获取短信验证码 */
export function getSendCode(data) {
  return request('/web/sendCode',data,{ method: 'get' })
}

/* 登录方法 */
export function login(data) {
  return request('/web/login?phone=' + data.phone + '&code=' + data.code, {},{ method: 'post' })
}

/* 退出方法 */
export function logout(data) {
  return request('/web/logout', '',{ method: 'post', data })
}


/* 获取图形验证码 */
export function getCodeImg(data) {
  return request('/captchaImage',data,{ method: 'get' })
}


<template>
  <div class="containers" v-if="showFooter == true">
    <div class="containersPage">
      <div class="footerBox">
        <div class="footerInfo">
          <div class="footerInfo_1">
            <div class="footerLog">
              <img :src="logImg" />
            </div>
            <div class="footerCodeBox">
              <div class="codeBox">
                <img src="../assets/img/footer/media-2.png" class="codeImg" />
              </div>
              <div class="codeBox">
                <img src="../assets/img/footer/media-3.png" class="codeImg" />
              </div>
            </div>
            <div class="modeBox">
              <img class="modeImg" :src="modeImg1" />
              <img class="modeImg" :src="modeImg2" />
              <img class="modeImg" :src="modeImg3" />
            </div>
          </div>
          <div class="footerInfo_2">
            <div class="footerTitle">旗下品牌</div>
            <a>中科心客</a>
            <a>技转云</a>
            <a>滕王阁峰会</a>
          </div>
          <div class="footerInfo_2">
            <div class="footerTitle">电视栏目</div>
            <a>东方卫视</a>
            <!-- <a>江西卫视</a> -->
          </div>
          <div class="footerInfo_2">
            <div class="footerTitle">联系我们</div>
            <a>汪女士</a>
            <a>010-82666135</a>
            <a>leichuan@yjdjs.com.cn</a>
          </div>
          <div class="footerInfo_2" style="margin:0 10px 0 50px;">
            <div class="footerTitle">投诉监督</div>
            <a>监督投诉热线</a>
            <a>18600613123</a>
            <a>监督投诉邮箱</a>
            <a>hegui@yjdjs.com.cn</a>
          </div>
          <div class="footerInfo_5">
            <div class="footerTitle">预见独角兽热线</div>
            <a>40004-99997（周一到周五09:00-18:00）</a>
            <div class="footerTitle">品牌事务合作</div>
            <a>service@thinkerwork.com</a>
          </div>
        </div>
      </div>
      <!-- 弹出框 -->
      <el-row style="background-color: #212121;width: 100%">
        <el-col :span="24">
          <div class="copyright">
            <div class="flex justify-center align-center" style="height: 100%; color: #fff;padding: 5px 0;">
              版权所有© 预见独角兽 |
              <!-- <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery?recordcode=赣ICP备12000995号-2" style="margin-left: 0.25rem;">赣ICP备12000995号-2</a> -->
              <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="margin-left: 0.25rem;">京ICP备2022035061号</a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 底部手机端 -->
    <div class="footerContent containersPage2">
      <div class="footerLogImgBox centerAlign">
        <el-image @click.stop="handleUrl()" style="width: 56px;height: 40px;" fit="cover" src="http://file.thinkervc.cn/tnj6f48h9yfkfkkr8etpoe1f221tebqrou1dnbkfudi96nfw6xd5vx5wziee8i5g68dxp2n2kb4pg50r.png"></el-image>
      </div>
      <div class="footerIntroduceBox flex_between" style="border: 0;">
        <span style="text-align: left;">关于预见独角兽</span>
        <span>旗下品牌</span>
        <span style="text-align: right;" @click.stop="popupFun(3)">电视栏目</span>
      </div>
      <div class="footerIntroduceBox flex_between">
        <span style="text-align: left;" @click.stop="popupFun(0)">预见独角兽热线</span>
        <span @click.stop="popupFun(1)">联系我们</span>
        <span style="text-align: right;" @click.stop="popupFun(2)">品牌事务合作</span>
      </div>
      <div class="aqText">
        <span>©2021~2023 预见独角兽 |</span>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="margin-left: 0.25rem;color: #929292;">京ICP备2022035061号</a>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog title="" :visible.sync="popupShow" width="79%" center :show-close="false">
      <div v-if="popupInx == 0">
        <div class="popupTitle">预见独角兽热线</div>
        <div class="popupText">
          <label>40004-99997</label>
          <label>(周一到周五09:00-18:00)</label>
        </div>
      </div>
      <div v-if="popupInx == 1">
        <div class="popupTitle">联系我们</div>
        <div class="popupText">
          <label>吴女士</label>
          <label>010-82669705</label>
          <label>wudi@yjdjs.com.cn</label>
        </div>
      </div>
      <div v-if="popupInx == 2">
        <div class="popupTitle">品牌事务合作</div>
        <div class="popupText">
          <label>service@thinkerwork.com</label>
        </div>
      </div>
      <div v-if="popupInx == 3">
        <div class="popupTitle">电视栏目</div>
        <div class="popupText">
          <label>东方卫视</label>
          <label>江西卫视</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="popupBut" @click="popupShow = false">我知道了</el-button>
      </span>
    </el-dialog>
    <PoupCode :img-url="'../../assets/img/qrcode/qrcode_community.png'" :center-dialog-visible="centerDialogVisible" @poupClose="centerDialogVisible = false" @poupOpen="centerDialogVisible = true" />
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      centerDialogVisible: false,
      logImg: "http://file.thinkervc.cn/w6zpy4c51xu4rgkb07dglnri80uxpnleuhckhcct3ctvwmdxo2vfzh3lhpsffuanxoxe6sm7d8o3zjui.png",
      modeImg1: "http://file.thinkervc.cn/r6g13gxvmgn2vcymuzh0g6kplp38trmefd733ug6gv64srgmt96aesk3iochv4hquw7vnlwogrsi20ql.png",
      modeImg2: "http://file.thinkervc.cn/ftq1s1x10fwaya5znhhcrar3yjej24nl571mm56vzoyd1zuvpvm6e4wtk3ballgqg8djvyhc6reaqjpd.png",
      modeImg3: "http://file.thinkervc.cn/v9ke8nj1vfm4w84cahzthu8hod7hctem0p5q2dkjj6djyk96j6j7qkenkgw10oqdtwtlqc6qw0fkjnum.png",
      showFooter: false,
      popupShow: false,
      // 0预见独角兽热线,1联系我们,2品牌事务合作,3电视栏目
      popupInx: 0
    };
  },
  mounted () {},
  created() {
    setTimeout(() => {
      this.showFooter = true;
    }, 800)
  },
  methods: {
    /** 打开弹框 **/
    popupFun(inx) {
      this.popupInx = inx;
      this.popupShow = true;
    },
    handleUrl() {
      this.$router.push({ name: "Home", query: {} });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex_between {
	display: flex;
	justify-content: space-between;
}
.centerAlign{
	display: flex;
	align-items: center;
}
.containersPage{
  display: block;
}
.containersPage2{
  display: none;
}
.footerBox{
  width: 100%;
  background-color: #303539;
  padding: 40px 0 45px 0;
}
.footerInfo{
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.footerInfo_1{
  width: 200px;
}
.footerLog{
  width: 100%;
  height: 111px;
  text-align: center;
  margin-bottom: 20px;
}
.footerLog img{
  width: 164px;
  height: 111px;
  cursor: pointer;
}
.footerCodeBox{
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.codeBox{
  width: 96px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #e1dfdf;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.codeImg{
  width: 93px;
  height: 93px;
  cursor: pointer;
}
.modeBox{
  height: 33px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.modeImg{
  width: 33px;
  height: 33px;
  cursor: pointer;
}
.footerInfo_2{
  padding-top: 56px;
  width: 96px;
}
.footerTitle{
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 32px;
  text-align: left;
}
.footerInfo_2 a{
  width: 100%;
  display: inline-block;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  margin-bottom: 32px;
  cursor: pointer;
  white-space: nowrap;
}
.footerInfo_5{
  padding-top: 56px;
  width: 310px;
}
.footerInfo_5 a{
  width: 100%;
  display: inline-block;
  font-size: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  margin-bottom: 32px;
  cursor: pointer;
}
// .el-row{
//   width:1200px;
//   margin: auto;
// }

/* 修改弹窗样式 */
::v-deep .el-dialog {
  height: 30rem;
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: #fff;
}
a:hover,
a:visited,
a:link,
a:active {
  color: #fff;
}

a:hover {
  color: #f08519;
}

.containers {
  width: 100%;
  background: #303539;
  box-sizing: border-box;
  overflow-x: hidden;
}
@media screen and (max-width: 1200px) {
  .containersPage{
    display: none;
  }
  .containersPage2{
    display: block;
  }
  .containers {
    width: 100%;
    padding: 0;
    background: #f9f9f9;
    margin-top: 40px;
  }
  .footerBox{
    padding: 20px 0;
  }
  .footerInfo{
    width: 94%;
    margin: 0 3%;
    display: inline-block;
  }
  .footerInfo_1{
    float: left;
    width: 70%;
    margin: 0 15%;
    overflow: hidden;
  }
  .footerInfo_2{
    float: left;
    width: 33%;
    overflow: hidden;
    padding-top: 34px;
  }
  .footerInfo_2 a{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .footerTitle{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .footerInfo_5{
    float: left;
    width: 100%;
    padding-top: 20px;
  }
  .footerInfo_5 a{
    font-size: 16px;
    margin-bottom: 20px;
  }
  //手机端
  .footerContent{
    background: #f9f9f9;
    padding: 0 32px;
    overflow: hidden;
    width: 100%;
  }
  .footerLogImgBox{
    height: 84px;
  }
  .footerIntroduceBox{
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    text-align: center;
    color: #929292;
    padding-bottom: 16px;
    border-bottom: 1px solid #929292;
  }
  .footerIntroduceBox span{
    width: 33%;
    text-align: center;
  }
  .aqText{
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: 400;
    text-align: center;
    color: #929292;
    line-height: 46px;
  }
  /* 修改弹窗样式 */
  // ::v-deep .el-dialog__wrapper{
  //   width: calc(100% - 1rem);
  //   height: 100vh;
  //   right: auto;
  //   bottom: auto;
  // }
  ::v-deep .el-dialog {
    height: auto;
    padding: 24px 0;
    border-radius: 15px;
    overflow: hidden;
    margin: 25vh auto 50px auto !important;
    // margin-top: 25vh !important;
  }
  .popupTitle{
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 700;
    text-align: center;
    color: #000000;
    padding: 24px 0 16px 0;
  }
  .popupText{
    height: 80px;
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #848484;
    margin-bottom: 14px;
  }
  .popupText label{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .popupBut{
    width: 170px;
    background: #f08307;
    border-radius: 10px !important;
    color: #ffffff;
    border: 0;
  }
}

</style>

import Cookies from 'js-cookie'

// const TokenKey = 'Admin-Token'
const TokenKey = 'YY-Token';
const Phone = 'phone';
const VipStatus = 'vipStatus';

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getPhone() {
  return Cookies.get(Phone)
}

export function setPhone(phone) {
  return Cookies.set(Phone, phone)
}

export function removePhone() {
  return Cookies.remove(Phone)
}

export function getVipStatus() {
  return Cookies.get(VipStatus)
}

export function setVipStatus(phone) {
  return Cookies.set(VipStatus, phone)
}

export function removeVipStatus() {
  return Cookies.remove(VipStatus)
}
<template>
  <el-container
    :class="[isMobile ? 'self-wrap-class' : '']"
    :style="{ width: '100%' }"
  >
    <el-header><Header></Header></el-header>
    <div class="HeaderTop" v-if="'Home'.indexOf($route.name) < 0"></div>
    <el-main>
      <keep-alive
        :include="[
          'Home',
          'ActivityMore',
          'Enterprise',
          'Information',
          'Mechanism',
          'Needs',
          'Search'
        ]"
        ><router-view
      /></keep-alive>
    </el-main>
    <el-footer
      v-if="
        [
          'VCFrontier',
          'VCFrontierDetail',
          'latestActivity',
          'latestActivityDetail',
          'Map'
        ].indexOf($route.name) < 0
      "
      ><Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.bak.vue";
import Footer from "@/components/Footer.vue";
import { mapState, mapMutations } from "vuex";
// 点击统计
import { addClickStatistics } from "@/api/index.js";

export default {
  name: "Index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      clientWidth: 1200
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    })
  },
  // 使用watch 监听$router的变化,
  watch: {
    $route: function(to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // 调用点击统计
      this.statisticsClick();
    }
  },
  created() {
    this.initIsMobile();
  },
  mounted() {
    // this.$nextTick(() => {
    //   window.addEventListener('resize', () => {
    //     this.clientWidth = document.documentElement.clientWidth
    //   });
    // })
    // console.log(this.clientWidth)

    // 调用点击统计
    this.statisticsClick();
  },
  methods: {
    ...mapMutations([
      "initIsMobile" // 将 `this.increment()` 映射为 `this.$store.commit('increment')`
    ]),

    // 当跳转路由时，调用此接口
    statisticsClick() {
      return
      addClickStatistics().then(res => {
        if (res.code === 200) {
          console.log("点击统计成功", res);
        }
      });
    }
  }
};
</script>

<style lang="scss">
body {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
.HeaderTop{
  height: 60px;
}
.full-width {
  width: 100%;
}
// web 端: 一左一右，一右一左 排列 （ :nth-child(even){ flex-flow: row-reverse wrap } ）
.left-right-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.left-right-box:nth-child(even) {
  flex-flow: row-reverse wrap;
}
// 在 特定条件下 flex-flow: row-reverse wrap;
// 这里的条件是：是否是移动端（判断设备属性）
.will-rowReverse {
  display: flex;
  align-items: center;
  flex-flow: row-reverse wrap;
}

// 网页头部 巨幕
.container {
  .header-pic {
    position: relative;
    width: 100%;
    height: 36rem;
  }
}
.el-main {
  .el-carousel__container {
    height: 22.5rem;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-container {
  // width: 1200px;
  // width: 1200px;
  margin: auto;

  .el-header,
  .el-main,
  .el-footer {
    margin: 0;
    padding: 0;
    // max-width: 100vw;
  }

  .el-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // background-color: rgba($color: #000000, $alpha: 0.75);
    // box-shadow: 0 0.125rem 0.375rem #f3f3f3;
    z-index: 20;
  }

  .el-main {
    // padding: 1rem 0;
    min-height: 60vh;
    overflow: unset;
    // 05/14
    // 在 任何使用到 .self-fixedWidth 的地方都
    // 去掉了 相应元素的 class 内的 padding-lr
    // .self-fixedWidth{
    //   width:1200px!important;
    //   min-width: 1200px!important;
    //   margin: auto;
    // }
    @media screen and (min-width: 1200px) {
      .self-fixedWidth {
        width: 1200px !important;
        min-width: 1200px !important;
        margin: auto;
      }
    }
    .el-row {
      // 05/14 el-row 元素定宽 1200px
      box-sizing: border-box;
      // width:1200px;
      transition: all 0.5s;
      margin: auto;
    }

    @media screen and (min-width: 1200px) {
      .el-row {
        width: 1200px;
        margin: auto !important;
      }
      .HeaderTop{
        height: auto;
      }
    }
  }

  .el-footer {
    height: auto !important;
  }
}
.qrcode-box {
  width: 100%;
  text-align: center;
  .el-dialog {
    width: 50rem;
    height: auto !important;
  }
  .qrcode-img {
    width: 100%;
    text-align: center;
  }
}

.self-wrap-class {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .el-container {
    .el-header {
      background-color: #000000;
      display: none;
    }
  }
  .self-wrap-class {
    .self-fixedWidth {
      width: 100% !important;
    }
    .flex {
      display: flex;
      flex-wrap: wrap;
    }
    .qrcode-box {
      width: 100%;
      text-align: center;
      .el-dialog {
        width: 80% !important;
        height: auto !important;
        margin-top: 20vh !important;
      }
      .qrcode-img {
        width: 100%;
        text-align: center;
      }
    }
    .el-main {
      .container {
        width: 100%;
        margin: 0;
        padding: 0;
        .flex {
          display: flex;
          flex-wrap: wrap;
        }
      }
      .container-fixed-width {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
    .self-fixedWidth {
      width: 100%;
      min-width: 100%;
      margin: auto;
    }
    .el-row {
      width: 100%;
      margin: 0 !important;
    }
  }
  .HeaderTop{
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .el-container {
    .el-header {
      background-color: #000000;
      display: none;
    }
  }
  .self-wrap-class {
    .left-right-box:nth-child(even) {
      flex-flow: row wrap;
    }
    .will-rowReverse {
      flex-flow: row wrap;
    }
    .header-pic {
      height: 15rem !important;
    }
    .el-container {
      .el-main {
        .el-carousel__container {
          height: 12rem;
        }
        .container {
          .self-fixedWidth {
            width: 100% !important;
            min-width: 100% !important;
          }
          .content {
            .brief-introduction-box {
              padding: 1rem;
            }
          }
        }
      }
    }
  }
  .HeaderTop{
    height: auto;
  }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Cookies from 'js-cookie'
import { VueJsonp } from 'vue-jsonp'

// 地图引用
import VueAMap from 'vue-amap';

// 懒加载
// import VueLazyload from 'vue-lazyload'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

// 自适应浏览器
// import './utils/flexible.js'
import './assets/css/common.css';

// quill编辑器样式, 用于后台返回的微信公众号文章样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.config.productionTip = false;

// 自定义全局组件
import PoupCode from '@/components/PoupCode/PoupCode.vue'

// 全局 获取字典
import { getDictList } from '@/api/dicts'
Vue.component('PoupCode', PoupCode);
Vue.config.ignoredElements = ['wx-open-launch-weapp']
// 使用
Vue.use(ElementUI);
Vue.use(VueAMap);
Vue.use(VueJsonp)

// Vue.use(VueLazyload, {
//   loading: require('./assets/img/pic_loading.png'), // 加载中图片，一定要有，不然会一直重复加载占位图
//   error: require('./assets/img/pic_error.png') // 加载失败图片
// })

// 根据环境变量决定是否引入mock
// if (process.env.NODE_ENV !== 'production' && !process.env.VUE_APP_BACK_END_URL) {
//   const Mock = require('./mock/index').default
//   Mock.mockData()
// }

// 判断是否为 手机端
Vue.prototype.$isMobile = false
Vue.prototype.getDictList = getDictList

// 高德地图安全策略更新，使用jsapi时，script标签后添加
window._AMapSecurityConfig = {
  securityJsCode: "7338a9c0601e0d6ea2eb1e9459f12c3e"
};

// 地图插件初始化
VueAMap.initAMapApiLoader({
  // 高德key
  key: 'ae060f00840ca1c94e19ff2fb42fcca0', // 自己到官网申请，目前用的自己的
  // 插件集合 （插件按需引入）
  plugin: [
    "AMap.Autocomplete",// 输入提示插件
    "AMap.PlaceSearch",// POI搜索插件
    "AMap.Scale",// 右下角缩略图插件 比例尺
    "AMap.OverView",// 地图鹰眼插件
    "AMap.ToolBar",// 地图工具条
    "AMap.MapType",// 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor",// 编辑 折线多，边形
    "AMap.CircleEditor",// 圆形编辑器插件
    "AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
    "AMap.Geocoder",// 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
    "AMap.AMapUI",// UI组件
  ],
  v: "1.4.4",
  uiVersion: "1.0.11" // 版本号
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
